<template>
  <Loading v-if="isLoading" />
  <q-layout>
    <q-page-container style="height: 100vh;width: 100%;overflow-x: hidden;">
      <slot />
    </q-page-container>
  </q-layout>
</template>
<script setup>
import Loading from '@/components/Loading/Index.vue'
import { useLoadingStore } from '@/store'
import { storeToRefs } from 'pinia';

const { isLoading } = storeToRefs(useLoadingStore())

</script>
